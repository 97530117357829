import React from "react";
import { graphql } from "gatsby";
import { Testimonial } from "./Testimonial";
import { Section, SectionTitle } from "../Section";
import { getCaseStudyUrl } from "../../utils/urls";
import { MdxImageSharp } from "../../types/images";
import { Technology } from "../../utils/tech";
import { MdxTestimonialCarousel, TestimonialCarousel } from "./TestimonialCarousel";

export type MdxTestimonialData = {
  id: string;
  body: string;
  frontmatter: {
    avatar: MdxImageSharp;
    client: string;
    linkedIn: string;
    location: string;
    position: string;
    quote: string;
    reviewUrl?: string;
    case?: string;
    tech: Technology[];
  };
};

export type MdxTestimonialsData = {
  nodes: MdxTestimonialData[];
};

export const mdxTestimonialsQuery = graphql`
  fragment TestimonialsData on MdxConnection {
    nodes {
      id
      body
      frontmatter {
        avatar {
          ...AvatarImgData
        }
        client
        linkedIn
        location
        position
        quote
        reviewUrl
        case
      }
    }
  }
`;

export function mdxToTestimonial({ id, body, frontmatter }: MdxTestimonialData) {
  return {
    id,
    linkedIn: frontmatter.linkedIn,
    avatar: frontmatter.avatar,
    client: frontmatter.client,
    position: frontmatter.position,
    location: frontmatter.location,
    quote: frontmatter.quote,
    text: body,
    reviewUrl: frontmatter.reviewUrl,
    caseUrl: frontmatter.case && getCaseStudyUrl(frontmatter.case)
  };
}

export function mdxToTestimonials(mdx: MdxTestimonialsData) {
  return mdx.nodes.map(mdxToTestimonial);
}

type MdxTestimonialsSectionProps = {
  title?: string, testimonialsData: MdxTestimonialsData;
};

export const MdxTestimonialsSection: React.FC<MdxTestimonialsSectionProps> = ({
  testimonialsData,
  title
}) => {
  if (!testimonialsData?.nodes?.length) return null;

  return (
    <Section>
      <SectionTitle>
        {title || "Client Testimonials"}
      </SectionTitle>
      <MdxTestimonialCarousel testimonialsData={testimonialsData} />
    </Section>
  );
};
