import React from "react";
import { Person } from "../Person";
import { useInView } from "react-intersection-observer";
import cn from "classnames";
import { ImageType } from "../../types/images";
import * as styles from "./Testimonial.module.scss";
import { Link } from "../Link";
import { Image } from "../Image";
import { MdxRenderer } from "../Mdx/MdxRenderer";
import { Button } from "../Button";

export type Testimonial = {
  id: string;
  linkedIn: string;
  client: string;
  avatar: ImageType;
  position: string;
  quote: string;
  text: string;
  location?: string;
  reviewUrl?: string;
  caseUrl?: string;
};

type Props = Testimonial & { className?: string };

export const Testimonial: React.FC<Props> = ({
  linkedIn,
  client,
  avatar,
  position,
  location,
  quote,
  text,
  reviewUrl,
  caseUrl,
  className,
}) => {
  const [ref, inView] = useInView();

  return (
    <div
      ref={ref}
      className={cn(styles.testimonial, "show-up", className, {
        visible: inView,
      })}
    >
      <Link className={styles.person} to={linkedIn}>
        <Image
          className={styles.avatar}
          image={avatar}
          alt={client}
          title={client}
        />
        <h3 className={styles.personName}>{client}</h3>
        <span className={styles.personTitle}>{position}</span>
        <span className={styles.personTitle}>{location}</span>
      </Link>

      <div className={styles.content}>
        <h3 className={styles.title}>
          {reviewUrl ? <Link to={reviewUrl}>{quote}</Link> : quote}
        </h3>

        <span className={styles.text}>
          <MdxRenderer>{text}</MdxRenderer>
        </span>

        {/* {!!(tech && tech.length) && (
          <small>
            Technologies
            <TechTextList tech={tech} />
          </small>
        )} */}
        {!!caseUrl && (
          <Button className={styles.btn} to={caseUrl}>
            Read More
          </Button>
        )}
      </div>
    </div>
  );
};
