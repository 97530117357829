import React from "react";

import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from "react-responsive-carousel";
import { MdxTestimonialsData, mdxToTestimonials } from "./MdxTestimonials";
import { Testimonial } from "./Testimonial";
import chevronLeft from "../../images/chevron-left.svg";
import * as styles from "./TestimonialCarousel.module.scss";
import cn from "classnames";
type TestimonialCarouselProps = {
  testimonials: Testimonial[];
  className?: string;
};

export const TestimonialCarousel: React.FC<TestimonialCarouselProps> = ({
  testimonials,
  className,
}) => {
  return (
    <Carousel
      autoPlay
      infiniteLoop
      interval={3000}
      showStatus={false}
      showArrows={true}
      showThumbs={false}
      showIndicators={testimonials.length > 1}
      renderIndicator={(clickHandler, isSelected, index) => (
        <div
          onClick={clickHandler}
          className={cn(styles.customIndicator, {
            [styles.selected]: isSelected,
          })}
          key={index}
        ></div>
      )}
      className={cn(styles.carousel, className)}
      renderArrowPrev={(onClickHandler) => (
        <div
          onClick={onClickHandler}
          className={cn(styles.arrow, styles.customPrevArrow)}
        >
          <img src={chevronLeft} />
        </div>
      )}
      renderArrowNext={(onClickHandler) => (
        <div
          onClick={onClickHandler}
          className={cn(styles.arrow, styles.customNextArrow)}
        >
          <img src={chevronLeft} />
        </div>
      )}
    >
      {testimonials.map((x) => (
        <Testimonial key={x.id} {...x} className={styles.testimonial} />
      ))}
    </Carousel>
  );
};

type MdxTestimonialCarouselProps = {
  testimonialsData: MdxTestimonialsData;
  className?: string;
};

export const MdxTestimonialCarousel: React.FC<MdxTestimonialCarouselProps> = ({
  testimonialsData,
  ...rest
}) => {
  return (
    <TestimonialCarousel
      testimonials={mdxToTestimonials(testimonialsData)}
      {...rest}
    />
  );
};
